import React, { Component, Fragment } from 'react';
import Aboutsec from './Aboutsec';
import Counter from './Counter';
import Framework from './Framework';
import Post from './Post';
import Services from './Services';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Aboutsec/>
                {/* <Services/> */}
                {/* <Framework/> */}
                {/* <Counter/> */}
                {/* <Post/> */}
            </Fragment>
        );
    }
}

export default Content;