import React, { Component } from 'react';

import img1 from '../../../assets/img/pompa-otomasyonu/04.jpg';
import img2 from '../../../assets/img/pompa-otomasyonu/05.jpg';
import img3 from '../../../assets/img/experience-tag.png';

class Aboutsec extends Component {
    render() {
        return (
            <section className="about-section about-style-three pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                      
                        <div className="col-lg-6 col-md-10">
                            <div className="about-text pl-30">
                                <div className="section-title left-border mb-10">
                                    <span className="title-tag">VİZYON</span>
                                </div>
                                <p style={{fontSize:16}}>
                                Tecrübesi ile karmaşık çözümleri 
                                son kullanıcıya hızlı, etkin, güçlü, kullanımı kolay 
                                bir tepside sunarak sektörün de tercih edilen markası olmak.

                                </p>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{marginTop:50}} className="row align-items-center justify-content-center">
                      
                      <div className="col-lg-6 col-md-10">
                          <div className="about-text pl-30">
                              <div className="section-title left-border mb-10">
                                  <span className="title-tag">MİSYON</span>
                              </div>
                              <p style={{fontSize:16}}>
                                Hizmet kalitesinden ödün vermeden 
                                Kalite-uygun fiyat politikasıyla beklentileri en üst düzeyde karşılamak.
                              </p>
                          </div>
                      </div>
                      
                  </div>

                  <div style={{marginTop:50}} className="row align-items-center justify-content-center">
                      
                      <div className="col-lg-12 col-md-12">
                          <div className="about-text pl-30">
                              <div className="section-title left-border mb-10">
                                  <span className="title-tag">
                                      BİLGİ TEKNOLOJİLERİ HİZMET YÖNETİM SİSTEMİ POLİTİKASI
                                      </span>
                              </div>
                              <p style={{fontSize:16}}>
                              Trigon’un iç ve dış müşterilerine sunduğu IT hizmetlerinin güvenilir, tutarlı, 
                              kaliteli ve sürekli iyileştirme prensibine dayalı olarak uygun maliyetlerle, 
                              ilgili yasa ve yönetmeliklere uyarak sağlanabilmesi için ISO 20000-1 standardına dayalı hizmet yönetim sistemini kurmak, 
                              işletmek ve geliştirmek amacıyla oluşturulmuştur.
                              </p>
                              <p style={{fontSize:16}}>
                              Hizmet yönetimi ve gereksinimlerinde ulaşılmak istenen hedef, müşteri memnuniyetidir. 
                              Bu amaçla verilen hizmetlerden sonra düzenli olarak müşteri memnuniyetini belirlemeye yönelik sorgulama ve ölçme yöntemleri devreye alınmıştır.
                              </p>
                              <p style={{fontSize:16}}>
                              Müşteri odaklı olarak çalışan Trigon’un hedefi, 
                              servis seviye anlaşmalarını ve sözleşmeli müşteri şartlarını eksiksiz yerine getirebilmek için her bir servis sürecindeki süreç performans 
                              hedeflerini yakalamak, servis vermekte olduğu müşterilerinin iş sürekliliğini sağlamak ve servis süreçlerini iyileştirmektir
                              </p>  
                          </div>
                      </div>
                  </div>

                  <div style={{marginTop:50}} className="row align-items-center justify-content-center">
                      
                      <div className="col-lg-12 col-md-12">
                          <div className="about-text pl-30">
                              <div className="section-title left-border mb-10">
                                  <span className="title-tag">
                                      BİLGİ GÜVENLİĞİ POLİTİKASI
                                      </span>
                              </div>
                              <p style={{fontSize:16}}>
                              TRIGON faaliyetleri esnasında hizmet vermekte olduğu müşterilerinin hassas verilerine, 
                              özel erişim/bağlantı bilgilerine, kritik cihazlara ait özel konfigürasyon ve iletişim bilgilerine sahip olabilmektedir.
                              </p>

                              <p style={{fontSize:16}}>
                              Ürün ve hizmet sağlanan kurum ve kuruluşların güvenini temin etmek ve 
                              verdiğimiz hizmetler için kullandığımız bilgi varlıklarımızın güvenliğini sağlamamız öncelikli amacımızdır.  
                              </p>

                              <p style={{fontSize:16}}>
                              Bu bağlamda; iş birliğinde bulunduğumuz müşteriler, resmi kurumlar ve bayilerimiz ile ilişkilerimiz çok değerlidir. 
                              </p>

                              <p style={{fontSize:16}}>
                              Sunmakta olduğumuz ürün ve hizmetlerin sürekliliği, elimizde tuttuğumuz bilgilerin gizliliği, müşterilerin veya kendi içimizdeki bilgi varlıklarının bütünlüğü yüksek öneme sahiptir.
                              </p>

                              <p style={{fontSize:16}}>
                              Bu amaçla:
                              </p>

                              <p style={{fontSize:16}}>
                              •	ISO 20000-1:2018 ile entegre şekilde kurduğumuz ISO 27001:2013 sisteminin etkinliğini sürekli iyileştirmeyi taahhüt ederiz.
                              </p>

                              <p style={{fontSize:16}}>
                              •	Risklerimizi sürekli gözden geçiriyor ve kabul edilebilir seviyenin üstündeki riskler için kontroller uyguluyoruz.
                              </p>

                              <p style={{fontSize:16}}>
                              •	Ürün ve/veya hizmet verdiğimiz kişi ve/veya kurumlara ait hiçbir bilgiyi 3. şahıslarla paylaşmayacağımızı, 
                              bu kişi ve/veya kurumların bilgilerine yetkisiz olarak ulaşmaya çalışacak kötü niyetli kişilerin ataklarına karşı 
                              güncel güvenlik duvarlarını oluşturacağımızı taahhüt ederiz.
                              </p>

                              <p style={{fontSize:16}}>
                              •	Kişisel Verilerin Korunması (KVK) Kanunu gereksinimlerini karşılamak için donanım, yazılım, 
                              eğitim ve diğer kontroller için gerekli çalışmaları yürütmekteyiz.
                              </p>

                              <p style={{fontSize:16}}>
                              •	Çalışanların bilgi güvenliği farkındalığını arttırmak ve bu farkındalığın şirket kültürünün bir parçası haline getirmek amacıyla çalışmalar yürütmekteyiz.
                              </p>

                              <p style={{fontSize:16}}>
                              •	Türkiye Cumhuriyeti yasa ve yönetmeliklerine tam olarak uymayı taahhüt ederiz.
                              </p>
                              
                              <p style={{fontSize:16}}>
                              •	Bilgi Güvenliği Politikamızda yer alan amaç ve hedefleri destekleyen çalışmalar, 
                              her yıl oluşturulan Bilgi Güvenliği Planında yer alır ve bu çalışmaların ilerleme durumları, 
                              yıl içinde takip edilir ve raporlanmaktadır. Bilgi Güvenliği Yönetim Sisteminin sürekli iyileştirilmesi sağlanır, 
                              sürekli iyileştirmeye yönelik çalışmalar yönetim tarafından gözden geçirilmektedir.
                              </p>
                          </div>
                      </div>
                  </div>

                  <div style={{marginTop:50}} className="row align-items-center justify-content-center">
                      
                      <div className="col-lg-12 col-md-12">
                          <div className="about-text pl-30">
                              <div className="section-title left-border mb-10">
                                  <span className="title-tag">
                                      KALİTE ÇEVRE İŞ SAĞLIĞI VE GÜVENLİĞİ YÖNETİM SİSTEMİ POLİTİKASI
                                      </span>
                              </div>
                              <p style={{fontSize:16}}>
                              TRIGON kalite odaklı yaklaşımla hizmet verirken, küresel marka olma yolunda, sorumluluklarının bilinciyle faaliyetlerini sürdürmektedir. Bu bağlamda,
                              </p>

                              <p style={{fontSize:16}}>
                              -Uluslararası standartları, teknolojiyi ve kaliteyi bütün faaliyetlerde ana prensip kabul ederek müşteri memnuniyetinin sürekliliğini sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Kaliteyi sürekli kılarak müşterilerimizin ve çalışan personelimizin firmamıza duyduğu güvenin devamlılığını,
                              </p>

                              <p style={{fontSize:16}}>
                              -Firmamızın gelişimini sağlayacak her türlü eğitim ve bilinçlendirme çalışmalarına gereken önem ve hassasiyeti göstererek personel kalitesini ve memnuniyetini arttırmayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Firmamızın amacı, boyutları ve bağlamına ve riskleri ile fırsatlarının özel doğasına uygun faaliyet göstererek kaynakların etkili kullanılmasını sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Çevresel boyutları belirlemek, çevresel etkilerin türlerini ve zararları tespit etmek ve çevreye etkisi olabilecek boyutlara yönelik ilgili yasal şartlara ve mevzuata uymayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Kirliliği ve atıkları en aza indirmek ve tehlikeli atıklarımızın çevresel etkisi en aza indirgenmiş olarak bertarafını sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Çevre ile ilgili mümkün olan her alanda ölçülebilir sürekli gelişmeyi sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -İş kaynaklı yaralanmaları ve hastalıkları engellemek için sağlıklı ve güvenli çalışma şartlarını sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Yasal ve diğer şartları yerine getirmeyi,
                              </p>

                              <p style={{fontSize:16}}>
                              -Tehlikeleri ortadan kaldırma ve İSG risklerini azaltmayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Çalışanların entegre yönetim sistemine katılımını ve tam uyumu sağlamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Bu doğrultuda amaç ve hedefleri belirleyerek her yıl gözden geçirerek ilerlemeleri açıklamayı,
                              </p>

                              <p style={{fontSize:16}}>
                              -Paydaşların şikayetlerini çözüme kavuşturmak ve bağlılığı sağlamak birincil ilkemiz olup, şeffaflık, 
                              erişilebilirlik, cevap verebilirlik, objektiflik, gizlilik, hesap verebilirlik çerçevesinde faaliyetlerimizi sürdürmeyi,
                              </p>

                              <p style={{fontSize:16}}>
                              ve Entegre Yönetim Sisteminin etkinliğini sürekli iyileştirmeyi taahhüt ederiz.
                              </p>
                          </div>
                      </div>
                  </div>
                </div>
            </section>
        );
    }
}

export default Aboutsec;