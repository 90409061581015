import React from 'react';
import {Route, Switch } from 'react-router-dom';

// Preloader
import Preloader from './components/layouts/Preloader';
// Pages
import Home from './components/pages/Home';
import About from './components/pages/About';
import Policy from './components/pages/Policy';
import Portfoliogrid from './components/pages/Portfoliogrid';
import Portfoliomasonary from './components/pages/Portfoliomasonary';
import Contact from './components/pages/Contact';
import UttsOnTalep from './components/pages/uttsontalep';

function App() {

  return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About}/>
        <Route exact path="/privacy-policy" component={Policy}/>
        <Route exact path="/utts-on-talep" component={UttsOnTalep}/>
        <Route exact path="/portfolio-grid" component={Portfoliogrid} />
        <Route exact path="/portfolio-masonary" component={Portfoliomasonary} />
        <Route exact path="/contact-us" component={Contact} />
      </Switch>
  );
}

export default App;
