import React from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Slider from 'react-slick';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import imgTabanca from '../../../assets/img/pompa-otomasyonu/01.jpg';
import imgTank from '../../../assets/img/tank-otomasyonu/01.jpg';
import imgHaberlesme from '../../../assets/img/pompa-otomasyonu/02.jpg';
import imgPompa from '../../../assets/img/pompa-otomasyonu/03.jpg';

import img1 from '../../../assets/img/slider/1.jpg';
import img2 from '../../../assets/img/slider/2.jpg';
import img3 from '../../../assets/img/slider/3.jpg';
import img4 from '../../../assets/img/slider/4.jpg';



const pompaImages = [
    {
        img: imgTabanca,
        imgclass: "image-one",
    },
    {
        img: imgHaberlesme,
        imgclass: "image-two",
    },
    {
        img: imgPompa,
        imgclass: "image-three",
    },
]
const tankImages = [
    {
        img: imgTank,
        imgclass: "image-one",
    },
    {
        img: imgHaberlesme,
        imgclass: "image-two",
    },
    {
        img: imgPompa,
        imgclass: "image-three",
    }
]

export default function Counter() {
    const [focus, setFocus] = React.useState(false);
    const percentage = 75;
    const percentage2 = 85;

    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: true,
        fade: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
        slidersImage:[
            {
                image:img1,
                desc:"Satış yaparken temel prensibimiz; uygun ve rekabetçi fiyatlarla hem istasyonların ekipman maliyetlerini düşürmek hem de servis ve bayilerimizin kar marjlarını yüksek tutmaktır. Satışlarımızın tamamını gün geçtikçe yaygınlaşan bayi ağımız üzerinden yapmaktayız. Böylece, aynı zamanda servis hizmeti veren bu bayiler aracılığıyla, satışı yapılan ürünün bakım ve servis hizmetleri etkin ve hızlı bir şekilde yapılmakta, satış sonrası hizmette kalite yüksek seviyede tutulmaktadır.",
            },  
            {
                image:img2,
                desc:"Satış yaparken temel prensibimiz; uygun ve rekabetçi fiyatlarla hem istasyonların ekipman maliyetlerini düşürmek hem de servis ve bayilerimizin kar marjlarını yüksek tutmaktır. Satışlarımızın tamamını gün geçtikçe yaygınlaşan bayi ağımız üzerinden yapmaktayız. Böylece, aynı zamanda servis hizmeti veren bu bayiler aracılığıyla, satışı yapılan ürünün bakım ve servis hizmetleri etkin ve hızlı bir şekilde yapılmakta, satış sonrası hizmette kalite yüksek seviyede tutulmaktadır.",
            },  
            {
                image:img3,
                desc:"Satış yaparken temel prensibimiz; uygun ve rekabetçi fiyatlarla hem istasyonların ekipman maliyetlerini düşürmek hem de servis ve bayilerimizin kar marjlarını yüksek tutmaktır. Satışlarımızın tamamını gün geçtikçe yaygınlaşan bayi ağımız üzerinden yapmaktayız. Böylece, aynı zamanda servis hizmeti veren bu bayiler aracılığıyla, satışı yapılan ürünün bakım ve servis hizmetleri etkin ve hızlı bir şekilde yapılmakta, satış sonrası hizmette kalite yüksek seviyede tutulmaktadır.",
            },  
            {
                image:img4,
                desc:"Satış yaparken temel prensibimiz; uygun ve rekabetçi fiyatlarla hem istasyonların ekipman maliyetlerini düşürmek hem de servis ve bayilerimizin kar marjlarını yüksek tutmaktır. Satışlarımızın tamamını gün geçtikçe yaygınlaşan bayi ağımız üzerinden yapmaktayız. Böylece, aynı zamanda servis hizmeti veren bu bayiler aracılığıyla, satışı yapılan ürünün bakım ve servis hizmetleri etkin ve hızlı bir şekilde yapılmakta, satış sonrası hizmette kalite yüksek seviyede tutulmaktadır.",
               }

        ]
    };
 
    return (
        <section className="about-section pt-50 pb-120">

         
                    <Slider className="slider-active mb-50" id="bannerSliderOne" {...settings}>
                        {settings.slidersImage.map((item, z) => (
                            <div className="mb-120 ">
                                <div className="container">
                                    <div className="row" key={z} >
                                       <div className="col-lg-6 col-md-10">
                                            <div className="about-tile-gallery">
                                                <img width={500} src={item.image} alt="Imge" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-10">
                                        <div className="about-text pl-25">
                                            <p style={{fontSize:30}}>{item.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

               

            <div className="container">

                <div className="row align-items-center justify-content-center">
                    {/* <div className="col-lg-6 col-md-10">
                        <div className="about-tile-gallery">
                            {pompaImages.map((item, i) => (
                                <img key={i} src={item.img} className={item.imgclass} alt="" />
                            ))}
                        </div>
                    </div> */}
                    <div className="col-lg-12 col-md-10">
                        <div className="about-text pl-25">
                            <div className="section-title mb-40 left-border">
                                {/* <span className="title-tag">Otomasyon</span> */}
                                <h2>Pompa Otomasyonu</h2>
                            </div>
                            <p>
                                Her bir pompanın durumunun anlık olarak takip edilebildiği sade ekranda son satışlar liste halinde görülebilir.Satışı yapılan yakıt türüne göre temsili pompa ekranda renk değiştirir. Böylelikle satış takibi görsel olarak kolayca yapılabilir.Müşteri SCARD kullanmışsa ekranda kart çekildiğine dair işaret, kart numarası ve müşterinin adı belirir.Satış işlemi tamamlandığı zaman eş zamanlı olarak hem satış ekranına hem de müşteri cari bilgilerine işlenir.Her satış sonrası satış bilgileri veritabanına ve satış oranında puan müşteri hesabına eklenir.
                            </p>
                            <div className="about-features mt-50">
                                <div className="sngle-features">
                                    <div className="progressbar-sec">
                                        <CircularProgressbar className="chart"
                                            value={percentage}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#ffaa17',
                                                trailColor: '#e2e8ee',
                                            })} />
                                        <span className="icon"><i className="fas fa-gas-pump" /></span>
                                    </div>
                                    <div className="counter-box">
                                        <span className="timer">
                                            <CountUp start={focus ? 0 : null} end={3500} duration={2} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                        <VisibilitySensor
                                                            onChange={isVisible => {
                                                                if (isVisible) {
                                                                    setFocus(true);
                                                                }
                                                            }}
                                                        >
                                                            <span>+</span>
                                                        </VisibilitySensor>
                                                    </div>
                                                )}
                                            </CountUp>
                                        </span>
                                    </div>
                                    <div className="desc ml-50">
                                        <h4>Aktif Tabanca sayısı</h4>
                                        <p>Otomasyon sistemimiz ile aktif olarak yönetilen tabanca sayımız.</p>
                                    </div>
                                </div>
                                <div className="sngle-features">
                                    <div className="progressbar-sec">
                                        <CircularProgressbar className="chart"
                                            value={percentage2}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#ffaa17',
                                                trailColor: '#e2e8ee',
                                            })} />
                                        <span className="icon"><i className="fas fa-gas-pump" /></span>
                                    </div>
                                    <div className="counter-box">
                                        <span className="timer">
                                            <CountUp start={focus ? 0 : null} end={1920} duration={2} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                        <VisibilitySensor
                                                            onChange={isVisible => {
                                                                if (isVisible) {
                                                                    setFocus(true);
                                                                }
                                                            }}
                                                        >
                                                            <span>+</span>
                                                        </VisibilitySensor>
                                                    </div>
                                                )}
                                            </CountUp>

                                        </span>
                                    </div>
                                    <div className="desc ml-50">
                                        <h4>Aktif Pompa Sayısı</h4>
                                        <p>Otomasyon sistemimiz ile aktif olarak yönetilen pompa sayımız..</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{marginTop:100}} className="container">
                <div className="row align-items-center justify-content-center">
                    {/* <div className="col-lg-6 col-md-10">
                        <div className="about-tile-gallery">
                            {tankImages.map((item, i) => (
                                <img key={i} src={item.img} className={item.imgclass} alt="" />
                            ))}
                        </div>
                    </div> */}
                    <div className="col-lg-12 col-md-10">
                        <div className="about-text pl-25">
                            <div className="section-title mb-40 left-border">
                                {/* <span className="title-tag">Otomasyon</span> */}
                                <h2>Tank Otomasyonu</h2>
                            </div>
                            <p>
                            PC üzerinden sade arayüzü sayesinde tüm tankların seviyesinin kolayca izlenebilmesi. Günlük satış rakamları istatistiğine göre kalan yakıt için tahmini zaman hesaplama Otomatik kalibrasyon özelliği sayesinde, akaryakıt seviyesinin en verimli şekilde takip edilebilmesi.Mevcut pompa otomasyon sistemi entegrasyonu ile, dolum sırasında yapılan satışları hesaplayarak ayarlı dolum raporu verme.Yakıt türüne göre stoktaki yakıt miktarını takip edebilme.Esnek sistem tasarımı sayesinde yeni teknolojilerle ve Trigon ürünlerine kolay entegrasyon.
          </p>
                            <div className="about-features mt-50">
                                <div className="sngle-features">
                                    <div className="progressbar-sec">
                                        <CircularProgressbar className="chart"
                                            value={percentage}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#ffaa17',
                                                trailColor: '#e2e8ee',
                                            })} />
                                        <span className="icon"><i className="fas fa-award" /></span>
                                    </div>
                                    <div className="counter-box">
                                        <span className="timer">
                                            <CountUp start={focus ? 0 : null} end={1420} duration={2} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                        <VisibilitySensor
                                                            onChange={isVisible => {
                                                                if (isVisible) {
                                                                    setFocus(true);
                                                                }
                                                            }}
                                                        >
                                                            <span>+</span>
                                                        </VisibilitySensor>
                                                    </div>
                                                )}
                                            </CountUp>
                                        </span>
                                    </div>
                                    <div className="desc ml-50">
                                        <h4>Aktif Tank sayısı</h4>
                                        <p>Otomasyon sistemimiz ile aktif olarak yönetilen tank sayımız.</p>
                                    </div>
                                </div>
                                <div className="sngle-features">
                                    <div className="progressbar-sec">
                                        <CircularProgressbar className="chart"
                                            value={percentage2}
                                            strokeWidth={5}
                                            styles={buildStyles({
                                                strokeLinecap: 'butt',
                                                pathTransitionDuration: 0.5,
                                                pathColor: '#ffaa17',
                                                trailColor: '#e2e8ee',
                                            })} />
                                        <span className="icon"><i className="fas fa-globe" /></span>
                                    </div>
                                    <div className="counter-box">
                                        <span className="timer">
                                            <CountUp start={focus ? 0 : null} end={21341547} duration={2} redraw={true}>
                                                {({ countUpRef }) => (
                                                    <div>
                                                        <span ref={countUpRef} />
                                                        <VisibilitySensor
                                                            onChange={isVisible => {
                                                                if (isVisible) {
                                                                    setFocus(true);
                                                                }
                                                            }}
                                                        >
                                                            <span>+</span>
                                                        </VisibilitySensor>
                                                    </div>
                                                )}
                                            </CountUp>

                                        </span>
                                    </div>
                                    <div className="desc ml-50">
                                        <h4>AKTİF STOK HACMİ</h4>
                                        <p>Otomasyon sistemimiz ile yönetilen toplam stok hacmi.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

