import React, { Component } from 'react';

class UTTS extends Component {
    render() {
        return (
            <section className="about-section about-style-three pt-120 pb-120" style={{"height":"100%"}}>
                        <iframe src="http://utts.onlinebpet.com.tr" height={"1000px"} width={"100%"}/>     
            </section>
        );
    }
}

export default UTTS;