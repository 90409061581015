import React, { Component, Fragment } from 'react';
import About from './About';
import Banner from './Banner';
import Referance from './Referance';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner/>
                <About/>
                {/* <Services/> */}
                {/* <Cta/> */}
                <Referance/>
                {/* <Features/>
                <Video/>
                <Team/>
                <Skills/>
                <Portfolio/> */}
                {/* <Testimonials/> */}
                {/* <Blog/> */}
            </Fragment>
        );
    }
}

export default Content;