import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import Slider from 'react-slick';

// Image
import img1 from '../../../assets/img/pump.png';
import img2 from '../../../assets/img/tank_1.png';
import img3 from '../../../assets/img/tcenter.jpg';
import img4 from '../../../assets/img/tanker.png';
import img5 from '../../../assets/img/mobil-app/main.jpg';
import img6 from '../../../assets/img/mobil-app/detail.jpg';

const filtersbtn = [
    { name: "ibys", value: "İSTASYON BİLGİ YÖNETİM SİSTEMİ" },
    { name: "tcenter", value: "TRIGON CENTER" },
    { name: "tankerOtm", value: "TANKER OTOMASYONU" },
    { name: "sbys", value: "SAHA BİLGİ YÖNETİM SİSTEMİ" },
    { name: "lkp", value: "LPG KONTROL PANOSU" },
];
class Portfoliocontent extends Component {
    constructor() {
        super();
        this.state = {
            strains: [
                {
                    origin:"ibys",
                    detail:[
                        {
                            photos: [{photo:img1}],
                            title: "POMPA OTOMASYONU",
                            descriptions:[
                            {
                                title:"",
                                description:"Her bir pompanın durumunun anlık olarak takip edilebildiği sade ekranda son satışlar liste halinde görülebilir.Satışı yapılan yakıt türüne göre temsili pompa ekranda renk değiştirir. Böylelikle satış takibi görsel olarak kolayca yapılabilir.Müşteri SCARD kullanmışsa ekranda kart çekildiğine dair işaret, kart numarası ve müşterinin adı belirir.Satış işlemi tamamlandığı zaman eş zamanlı olarak hem satış ekranına hem de müşteri cari bilgilerine işlenir.Her satış sonrası satış bilgileri veritabanına ve satış oranında puan müşteri hesabına eklenir.",
                            },
                            {
                                title:"Promosyon Sistemi",
                                description:"Manuel puan girişinde yakıt türünü X3 olarak kaydeder. Böylece usulsüz puan ekleme  önüne geçilir.",
                            },
                            {
                                title:"Müşteri Takip Sistemi",
                                description:"Borç sorgulama bölümü opsiyoneldir. Eğer veresiye takip sistemi de bütünleşik olarak tercih edilmişse, bu özellik sayesinde veresiye ve nakit satışların herbiri ilgili müşterinin cari bilgisine otomatik yansıyacaktır.",
                            },
                            {
                                title:"Pompacı Takip Sistemi",
                                description:"32 veya 64 adet pompacıya kadar destekler.Her pompacı için detaylı bilgi giriş imkanı sağlar.Pompacı bazında vardiye satış takibi sunar.Tek tuşla herhangi bir pompacının bilgilerine ulaşılabilir.Herhangi bir pompacının tüm zamanlardaki satış / performans bilgileri kolayca görüntülenebilir.",
                            },
                            {
                                title:"Vardiya Takip Sistemi",
                                description:"16 adet pompaya kadar destekler.Her pompa için vardiya boyunca yapılan satışı gösterir.Her yakıt türü için ayrı ayrı vardiya boyunca yapılan satış tutarını gösterir.Tek tuşla herhangi bir pompanın vardiya satış bilgilerine ulaşılabilir.Herhangi bir pompacının tüm zamanlardaki satış / performans bilgileri kolayca görüntülenebilir.",
                            }
                            ]
                        },
                        {
                            photos: [{photo:img2}],
                            title: "Tank OTOMASYONU",
                            descriptions:[
                            {
                                title:"",
                                description:"PC üzerinden sade arayüzü sayesinde tüm tankların seviyesinin kolayca izlenebilmesi. Günlük satış rakamları istatistiğine göre kalan yakıt için tahmini zaman hesaplama Otomatik kalibrasyon özelliği sayesinde, akaryakıt seviyesinin en verimli şekilde takip edilebilmesi.Mevcut pompa otomasyon sistemi entegrasyonu ile, dolum sırasında yapılan satışları hesaplayarak ayarlı dolum raporu verme.Yakıt türüne göre stoktaki yakıt miktarını takip edebilme.Esnek sistem tasarımı sayesinde yeni teknolojilerle ve Trigon ürünlerine kolay entegrasyon.",
                            },
                            {
                                title:"Manifold",
                                description:"Akıllı kodlama ile manifold yönetiminde bir cihaza ihtiyaç duyulmaz. Manifold'lu tanklar arasında düşüş algılanır ve aktif olan tank belirlenir. Bu sayede sizlere cihaz maliyeti oluşturmaz.",
                            },
                            {
                                title:"Data Kalibrasyon",
                                description:"Ek bir cihaza ücret ödemeden, lazer kalibrasyon yaptırmadan akıllı kodlama ile kalibrasyon işlemi yapılmaktadır.",
                            }
                            ]
                        }
                    ]
                },
                {
                    origin:"tcenter",
                    detail:[
                        {
                            photos: [{photo:img3}],
                            title: "TRIGON CENTER",
                            descriptions:[
                                {
                                    title:"",
                                    description:"3 farklı mod ile çalışan tek URL ile milyonlarca veriye saniyeler içerisinde erişim sağlanır. Bayi tanımlamalarını, otomasyon alarm sistemlerinin yönetimini, veri gönderim durumlarının takibini ve EPDK raporlarının takibini yapabileceğiniz kullanıcı dostu arayüzü ile hizmet vermekte. ",
                                },
                                {
                                    title:"MOD 1: EPDK",
                                    description:"Tek URL desteği sağlayan ve EPDK istekleri doğrultusunda hazrılanmış raporların gösterimi.",
                                },
                                {
                                    title:"MOD 2: Dağıtıcı",
                                    description:"Dağıtıcının kullanacağı bu mod; kullanıcı dostu arayüzü ile otomasyonun tüm süreçlerini takip edebilir, alarmları anlık olarak izleyebilir ve çeşitli raporlamalar ile kar-zarar, riskli yatırım veya karlı yatırım gibi analizleri çıkarabilirsiniz.",
                                },
                                {
                                    title:"MOD 3: Bayi",
                                    description:"Bayiler anlık stok durumlarını ve satışlarını takip edebilir ve çeşitli sorgulamaları yapabilir.",
                                }
                            ]
                        }
                    ]
                },
                {
                    origin:"tankerOtm",
                    detail:[
                        {
                            photos: [{photo:img4}],
                            title: "TANKER OTOMASYONU",
                            cat: "uix design",
                        }
                    ]
                    
                },
                {
                    origin:"sbys",
                    detail:[
                        {
                            photos: [{photo:img5},{photo:img6}],
                            title: "Mobil Uygulama",
                            descriptions:[
                                {
                                    title:"",
                                    description:"3 farklı mod da çalışır. Saha, yönetim ve bayi üçgeninde koordine sağlar ve çeşitli raporlamalar sunar. Algoritmik yapılarla analiz edilebilir raporlar oluşturur. Bayi ve potansiyel müşteri ziyaretlerinin real çizgide yapılmasını sağlar.",
                                },
                                {
                                    title:"MOD 1: Yönetim",
                                    description:"Saha ekibinin yaptığı müşteri ziyaretlerini anlık olarak takip edebilir ve satış-stok bilgilerine erişebilir. Sahanın durumunu analiz ederek ziyaret planını oluşturabilir.",
                                },
                                {
                                    title:"MOD 2: Saha Satış Ekibi",
                                    description:"Bayi ve potansiyel müşreti ziyareti gerçekleştirir. Bayilerin stok, sözleşme, satış bilgilerine erişebilir. Görüşme detaylarını sisteme girer.",
                                },
                                {
                                    title:"MOD 3: Bayi",
                                    description:"Bayiler anlık stok durumlarını ve satışlarını takip edebilir ve çeşitli sorgulamaları yapabilir.",
                                }
                            ]
                        }
                    ]
                  
                }
            ],
            filterStrain: [],
            _description:[]
        };
    }
    componentDidMount() {
        this.setState({
            filterStrain: this.state.strains
        });
    }
    handleClick = name => {
        let filterStrain = [];
        if (name === "All Works") {
            filterStrain = this.state.strains;
        } else {
            filterStrain = this.state.strains.filter(
                (strain, i) => strain.origin === name
            );
        }

        this.setState({ filterStrain });
    };

    
    render() {
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            dots: true,
            fade: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false,
                    },
                },
            ],
        };
     

        const imagesLoadedOptions = {
            itemSelector: '.masonry-item',
            percentPosition: false,
            gutter: 30,
            resize: true,
            fitWidth: true
        };
        const renderAll = this.state.filterStrain.map((portfolio, i) => (
          
        <div>
            {portfolio.detail.map((dt,x)=>
                <div class="container mb-60">
                <div class="row" >
                    <div class="col-sm-12">
                        <div className="section-title left-border mb-40 col-md-12">
                            <span className="title-tag">{dt.title}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <Slider className="slider-active" id="bannerSliderOne" {...settings}>
                        {dt.photos.map((item, z) => (
                            <div key={z} className="single-banner">
                                <img src={item.photo} alt="Imge" />
                            </div>
                        ))}
                        </Slider>
                    </div>
                    <div class="col-sm-8">

                        {dt.descriptions!=undefined?(
                            
                            dt.descriptions.map((desc,y)=>
                                <div>
                                    <h5 className={desc.title!=""?"mb-10 mt-30":"mb-10"}>{desc.title}</h5>
                                    <p>
                                        {desc.description}
                                    </p>
                                </div>
                            )
                        ):
                        null
                        }

                    </div>
                </div>
                </div>
            )
            }
           </div>
     

        ));
        
        return (
            <section className="portfolio-section pt-120 pb-90">
                <div className="container">
                    {/* portfolio filter */}
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="portfolio-filter masonry-filter">
                                <ul>
                                    {filtersbtn.map(({ name, value }) => (
                                        <li style={{fontFamily:'sans-serif'}} key={name} value={value} onClick={this.handleClick.bind(this, name)}>{value}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* portfolio loop */}
                    <Masonry className="row portfolio-masonary-loop masonry-items" imagesLoadedOptions={imagesLoadedOptions}>
                        {renderAll}
                    </Masonry>
                </div>
            </section>
        );
    }
}

export default Portfoliocontent;