import React, { Component } from 'react';

import img1 from '../../../assets/img/pompa-otomasyonu/04.jpg';
import img2 from '../../../assets/img/pompa-otomasyonu/05.jpg';
import img3 from '../../../assets/img/experience-tag.png';

class MobilPrivacyPolicy extends Component {
    render() {
        return (
            <section className="about-section about-style-three pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                      
                        <div className="col-lg-12 col-md-10">
                            <div className="about-text pl-30">
                                <div className="section-title left-border mb-10">
                                    <span className="title-tag">GİZLİLİK POLİTİKASI</span>
                                </div>
                                <p style={{fontSize:16}}>Gizlilik Politikası ve Kullanım Koşulları</p>
                                <p style={{fontSize:16}}>Burada belirtilen gizlilik politikası ve kullanım koşulları; Trigon Teknoloji’in Google Play Store ve IOS App Store'da yayınlanan bütün mobil uygulamaları için geçerlidir. Bu uygulamaları mobil cihazınıza yükleyerek, bu metinde yer alan gizlilik politikasını ve kullanım koşullarını kabul etmiş sayılırsınız. Bu koşulları kabul etmiyorsanız bu uygulamaları mobil cihazınıza yüklemeyiniz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Telefon Kullanım İzinleri, Uygulamamızda bulunan kısayol arama işlemi için gerekli olan izinlerin tarafınızdan sağlanmış olması gerekir.Verilen bu izin sadece Uygulamada kayıtlı olan müşterilerinizin kısayol aramlarında kullanılmaktadır ve müşterilerinize ait kişisel veriler sadece size özel veritabanlarında şifreli olarak saklanır.</p>
                                <br/>
                                <p style={{fontSize:16}}>İnternet Kullanım İzinleri,Uygulamımızdan Verilerin Şifreli sunuculara gönderilebilmesi için gerekli olan izindir ve kullanıcıların bunu tanımlaması gerekir. bu izinler telefonunuzdan isteğiniz dışında bir bilgiyi transfer etmek için kullanılmaz. Verilen internet erişim izni sadece uygulama içerisinde dolduracağınız formlara ait veri ve fotoğraf transferi için kullanılmaktadır.</p>
                                <br/>
                                <p style={{fontSize:16}}>Kamera Kullanımı: Mobil Uygulamalarımızda kamera kullanımı sadece uygulama içersinden göndermek istediğiniz fotoğraflar için kullanılmaktadır. kullanıcı bilgisi dışında kullanımı söz konusu değildir. Mobil uygulamada çekilen fotoğraflar kullanıcıya ait şifreli ortamda saklanır ve sadece kendisinin ve bağlı olduğu kurumun erişimine açıktır. Fotoğraflar 3. şahıs yada kurumlar ile kullanıcı bilgisi dışında paylaşılmaz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Uygulamalarımız, size ait herhangi bir kişisel bilgiyi toplamaz ve sizden bu yönde bir talepte bulunmaz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Trigon Teknoloji, kaliteli ve yararlı uygulamalar yayınlamak için her zaman gereken özeni ve duyarlılığı gösterecektir. Buna rağmen, uygulamalarımızda yer alan içeriklerin beklentilerinizi karşılayacağı, size yararlı olacağı veya kesin doğru bilgiler içereceğine dair hiçbir taahhütte bulunmamaktayız. Uygulamaları olduğu gibi sunmaktayız. Bu sebeple, uygulamalarımızdan kaynaklı yaşanacak herhangi bir olumsuz durum için Trigon Teknoloji’ in sorumlu tutamayacağınızı kabul etmektesiniz. </p>
                                <br/>
                                <p style={{fontSize:16}}>Trigon Teknoloji, bu uygulamaların güvenliği konusunda alınabilecek tüm önlemleri almak için gereken ölçüde çaba sarf eder ve Google Play Geliştirici Programı Politikaları sözleşmesi kapsamındaki yükümlülüklerini yerine getirir. Bununla birlikte; internet ve dijital ortamlar yeterli düzeyde güvenli alanlar değildir. Bu yüzden size yüzde yüz güvenli bir hizmet sunacağımız konusunda herhangi bir garantide bulunmamaktayız.Uygulamalarımız sadece Google Play Store ve IOS App Store'da yer almaktadır. Bu uygulamaların bizim bilgimiz dışında başka bir android yada IOS mağazasında yer alması durumunda, buradan yapılacak yüklemelerden Trigon Teknoloji sorumlu tutulamaz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Bu uygulamalarda, üçüncü taraflara ait reklamlar ve linkler yer alabilir. Bu üçüncü taraflara ait reklamların ve linklerin niteliğinden, içeriğinden, güvenliğinden veya bunlardan kaynaklı oluşabilecek zararlardan Trigon Teknoloji 'nin sorumlu tutamayacağınızı kabul etmektesiniz. Google tarafından yayınlanan reklamlara ilişkin ayarlarınızı nasıl düzenleyeceğinizi, reklam ayarları sayfasından öğrenebilirsiniz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Bu uygulamalarda yer alan sesli, yazılı ve görsel öğelerden ve yazılımlardan oluşan bütün içeriğe ilişkin her türlü telif hakkı Trigon Teknoloji’ne aittir. Herhangi bir uygulamamızı veya bu uygulamaların telif haklarıyla korunan içeriğini; kopyalama, çoğaltma, yeniden yayımlama, parçalarına ayırma, tekrar kamuya sunma vb. eylemlerde bulunmayacağınızı kabul etmektesiniz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Burada belirtilen koşullarla ilgili görüş ve önerilerinizi, mobil@trigonteknoloji.com mail adresinden bize iletebilirsiniz.</p>
                                <br/>
                                <p style={{fontSize:16}}>Trigon Teknoloji; bu Gizlilik Politikası ve Kullanım Koşulları metninde değişiklik yapabilir. Yapılan değişiklikler anında yürürlüğe girecektir. Değişiklik yaptığımız tarihi, "son güncelleme tarihi" olarak en alt kısımda belirtiriz. </p>
                                <br/>
                                <p style={{fontSize:16}}>Son güncelleme tarihi:</p>
                                <p style={{fontSize:16}}>01.01.2022</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        );
    }
}

export default MobilPrivacyPolicy;