import React, { Component } from 'react';

class Content extends Component {
    render() {
        return (
            <section className="contact-section with-map-bg pt-120 pb-120">
                <div className="container">
                    <div className="contact-form-wrapper">
                        <div className="row no-gutters align-items-center align-items-lg-start justify-content-center">
                            <div className="col-lg-5 col-md-5">
                                <div className="contact-info-list">
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="pe-7s-home" />
                                        </div>
                                        <div className="content">
                                            <h4>OFİS ADRESİ</h4>
                                            <p>Kayışdağı Cad. Ayşe Hatun Çeşme Sok. No:6 Kat:5-6 <br /> Ataşehir, İSTANBUL</p>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="pe-7s-call" />
                                        </div>
                                        <div className="content">
                                            <h4>Telefon Numaraları</h4>
                                            <p>0 (216) 307 92 92 <br /> 0 (216) 307 91 90</p>
                                        </div>
                                    </div>
                                    <div className="info-box">
                                        <div className="icon">
                                            <i className="pe-7s-comment" />
                                        </div>
                                        <div className="content">
                                            <h4>Email adresi</h4>
                                            <p>info@trigonteknoloji.com <br /> teknik@trigonteknoloji.com</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="contact-form">
                                    <form action="#">
                                        <div className="row padding-custom">
                                            <div className="col-12 col-lg-6">
                                                <div className="input-group mb-10">
                                                    <div className="icon">
                                                        <i className="fal fa-user" />
                                                    </div>
                                                    <input type="text" placeholder="Adınız" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className="input-group mb-10">
                                                    <div className="icon">
                                                        <i className="fal fa-envelope" />
                                                    </div>
                                                    <input type="text" placeholder="Soyadınız" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group mb-10">
                                                    <div className="icon">
                                                        <i className="fal fa-envelope" />
                                                    </div>
                                                    <input type="text" placeholder="E-Posta" />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-group textarea mb-10">
                                                    <div className="icon">
                                                        <i className="fal fa-edit" />
                                                    </div>
                                                    <textarea placeholder="Mesajınız" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button type="submit" className="main-btn btn-filled">Gönder</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-maps" style={{marginTop:40}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d753.0127905953991!2d29.10523302925304!3d40.98036051115262!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cac639b5ce7cf7%3A0x2a5013c08886b6ae!2zS8O8w6fDvGtiYWtrYWxrw7Z5LCBTb25heSBTay4gTm86NiwgMzQ3NTAgQXRhxZ9laGlyL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1624602663040!5m2!1str!2str" ></iframe>
                    </div>
                </div>
            </section>

        );
    }
}

export default Content;