import React, { Component, Fragment } from 'react';
import MobilPrivacyPolicy from './MobilPrivacyPolicy';
import UTTS from '../UTTS/utts';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <MobilPrivacyPolicy/>
                {/* <Services/> */}
                {/* <Framework/> */}
                {/* <Counter/> */}
                {/* <Post/> */}
            </Fragment>
        );
    }
}

export default Content;