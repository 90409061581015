import React, { Component, Fragment } from 'react';
import Cta from '../Portfoliogrid/Cta';
import Portfoliocontent from './Portfoliocontent';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Portfoliocontent/>
                {/* <Cta/> */}
            </Fragment>
        );
    }
}

export default Content;