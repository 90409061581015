import React, { Component, Fragment } from 'react';
import UTTS from '../UTTS/utts';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <UTTS/>
            </Fragment>
        );
    }
}

export default Content;