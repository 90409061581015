import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

// Client Image
import img1 from '../../../assets/img/technology-referance/1.png';
import img2 from '../../../assets/img/technology-referance/2.png';
import img3 from '../../../assets/img/technology-referance/3.png';
import img4 from '../../../assets/img/technology-referance/4.png';
import img5 from '../../../assets/img/technology-referance/5.png';
import img6 from '../../../assets/img/technology-referance/6.jpg';
import img7 from '../../../assets/img/technology-referance/7.png';

// Testimonial image
import quote from '../../../assets/img/testimonials/quote.png';
import tesimg1 from '../../../assets/img/testimonials/01.jpg';
import tesimg2 from '../../../assets/img/testimonials/02.jpg';
import tesimg3 from '../../../assets/img/testimonials/03.jpg';


// Client loop
const clientloop = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
];

// Testimonial post
const testimonialpost = [
    {
        img: tesimg1,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg2,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg3,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg1,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg2,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
    {
        img: tesimg3,
        text: "Our team can assist you in transforming your business through latest tech capabilities to stay ahead of the curve.",
        name: "Rosalina d. william",
        designation: "founder, cilixer co.",
    },
]
class Referance extends Component {
    render() {
 
        const clientslider = {
            infinite: true,
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        }
        return (
            <section className="testimonials-clinet-section pt-80 pb-80">
        
                <div className="clinet-section">
                    <div className="container-fluid custom-container-one">
                        <Slider className="clinet-slider" {...clientslider}>
                            {clientloop.map((item, i) => (
                                <div key={i} className="clinet-item">
                                    <Link to="#">
                                        <img height={100} src={item.img} alt="" />
                                    </Link>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </section>
        );
    }
}

export default Referance;